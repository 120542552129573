
import { supabase } from 'supabase'

async function getPayments(organizationId) {
    const { data, error } = await supabase
        .from('payments')
        .select('*')
        .eq('organization_id', organizationId)

    if (error) {
        console.log('error', error)
        return
    }

    return data
}
export default { getPayments }
