import {
  Box,
  SimpleGrid,
  Flex,
  Text,
  Input,
  FormControl,
  FormLabel,
  Button,
  useColorModeValue
} from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import Card from "components/card/Card.js"
import { HSeparator } from "components/separator/Separator"
import { useStore } from "store"
import helpers from 'utils/helpers'
import InputField from 'components/fields/InputField'

export default function Settings() {
  const { userProfile, saveUserProfile, updatePassword, addAlert } = useStore()
  const textColor = useColorModeValue("navy.700", "white")
  const brandStars = useColorModeValue("brand.500", "brand.400")
  const headerTextColor = useColorModeValue('secondaryGray.900', 'white')

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [phone, setPhone] = useState('')
  const [profileSaving, setProfileSaving] = useState(false)

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const updateUserProfile = async () => {
    if (!firstName || !lastName || !phone) {
      addAlert({ status: 'error', message: 'Please fill out all fields' })
      return
    }

    setProfileSaving(true)
    await saveUserProfile({ first_name: firstName, last_name: lastName, phone })
    setProfileSaving(false)
    addAlert({ status: 'success', message: 'Profile updated successfully' })
  }

  const handleSetNewPassword = async () => {
    if (newPassword && newPassword.length > 6) {
      if (newPassword === confirmNewPassword) {
        await updatePassword(newPassword)

        setNewPassword('')
        setConfirmNewPassword('')

        addAlert({ status: 'success', message: 'Password updated successfully!' })
      }
      else {
        addAlert({ status: 'error', message: 'Passwords do not match.' })
      }
    }
    else {
      addAlert({ status: 'error', message: 'New password must be at least 6 characters.' })
    }
  }

  useEffect(() => {
    if (userProfile) {
      setFirstName(userProfile.first_name)
      setLastName(userProfile.last_name)
      setPhone(helpers.formatPhone(userProfile.phone))
    }
  }, [userProfile])


  const renderUserSettings = () => {
    return (
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: "20px", xl: "20px" }}>
        <Card align='left' direction='column' >
          <Flex align='left' w='100%' px='15px' py='10px'>
            <Text
              color={headerTextColor}
              fontSize='xl'
              fontWeight={'bold'}>
              User Profile
            </Text>
          </Flex>
          <HSeparator mt={1} mb={2}></HSeparator>
          <Flex align='center' w={{ base: "100%", "2xl": "50%" }} px='15px' py='10px'>
            <FormControl>
              <InputField label={'First Name'}
                placeholder={'First Name'}
                required
                value={firstName}
                onChange={e => setFirstName(e.target.value.trim())} />
              <InputField label={'Last Name'}
                placeholder={'Last Name'}
                required
                value={lastName}
                onChange={e => setLastName(e.target.value.trim())} />

              <Flex justifyContent={'right'}>
                <Button
                  variant='brand'
                  w='100%'
                  mt={2}
                  onClick={updateUserProfile}
                  isLoading={profileSaving}
                  loadingText={'Saving'}>
                  Save Profile
                </Button>
              </Flex>
            </FormControl>
          </Flex>
        </Card>
        <Card align='left' direction='column' >
          <Flex align='left' w='100%' px='15px' py='10px'>
            <Text
              color={headerTextColor}
              fontSize='xl'
              fontWeight={'bold'}>
              Reset Password
            </Text>
          </Flex>
          <HSeparator mt={1} mb={2}></HSeparator>
          <Flex align='center' w={{ base: "100%", "2xl": "50%" }} px='15px' py='10px'>
            <FormControl>
              <InputField label={'New Password'}
                placeholder={'New Password'}
                required
                type={'password'}
                value={newPassword}
                onChange={e => setNewPassword(e.target.value.trim())} />
              <InputField label={'Confirm Password'}
                placeholder={'Confirm Password'}
                required
                type={'password'}
                value={confirmNewPassword}
                onChange={e => setConfirmNewPassword(e.target.value.trim())} />
              <Flex justifyContent={'right'}>
                <Button
                  variant='brand'
                  w='100%'
                  mt={2}
                  onClick={handleSetNewPassword}
                  isLoading={profileSaving}
                  loadingText={'Saving'}>
                  Set Password
                </Button>
              </Flex>
            </FormControl>
          </Flex>
        </Card>
      </SimpleGrid>
    )
  }

  return (
    <Box>
      <SimpleGrid
        mb={2}
        columns={{ base: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        {renderUserSettings()}

      </SimpleGrid>
    </Box >
  )
}
