import { Box, SimpleGrid } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import DynamicTable from "components/table"
import { useStore } from "store"
import { supabase } from "supabase"
import { useHistory } from "react-router-dom"

export default function RecordingsList() {
  const { userProfile } = useStore()
  const [files, setFiles] = useState([])
  const [filesLoading, setFilesLoading] = useState(true)
  const history = useHistory()

  const tableHeaders = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Date",
      type: 'date',
      accessor: "created_at",
    },
    {
      Header: "Duration",
      type: 'duration',
      accessor: "duration",
    },
    {
      Header: "Status",
      type: 'status',
      accessor: "status",
    },
    {
      Header: "",
      type: 'actions',
      accessor: "id",
    },
  ]

  const fetchFiles = async () => {
    const { data, error } = await supabase
      .from('recordings')
      .select('*')
      .eq('organization_id', userProfile.organization_id)

    if (error) {
      console.log(error)
    }
    else {
      setFiles(data)
      setFilesLoading(false)
    }
  }
  const onViewClick = (cell) => {
    history.push(`/recording/${cell.value}`)
  }

  useEffect(() => {
    if (userProfile) {
      fetchFiles()
    }
  }, [userProfile])

  return (
    <Box>
      <SimpleGrid
        mb='20px'
        columns={{ base: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <DynamicTable columnsData={tableHeaders}
          rowLimit={10}
          tableData={files} title={'Recordings'}
          onViewClick={onViewClick}
          emptyMessage={'no recordings uploaded... yet'}
          isLoading={filesLoading} />
      </SimpleGrid>
    </Box>
  )
}
