/*eslint-disable*/
import React from "react"
import {
  Flex,
  Link,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react"

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "gray.400")
  return (
    <Flex
      paddingTop={3}
      background={useColorModeValue("secondaryGray.400", "navy.800")}
      borderColor={useColorModeValue("secondaryGray.400", "#212d37")}
      borderTopWidth={1}
      justifyContent={{ base: 'center', md: 'right' }}>
      <Text
        color={textColor}
        mb={3}
        marginRight={{ base: '0px', md: '20px' }}
      >
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as='span' fontWeight='500' ms='4px'>
          <Link href="https://bleepit.co" target="_blank">bleepit.co</Link> | All Rights Reserved.
        </Text>
      </Text>
    </Flex>
  )
}
