import {
    SimpleGrid,
    Icon,
    Box,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem
} from "@chakra-ui/react"
import { useEffect, useState } from "react"
import MiniStatistics from "components/card/MiniStatistics"
import { MdAccessTime, MdAttachFile } from "react-icons/md"
import { SlSpeech } from "react-icons/sl"
import IconBox from "components/icons/IconBox"
import { useStore } from "store"
import usageService from "services/usageService"
import { MdArrowDropDown } from "react-icons/md"
import helpers from "utils/helpers"

function Metrics(props) {
    const { } = props
    const [selectedTimeframe, setSelectedTimeframe] = useState('This Month')
    const { organizationId } = useStore()
    const [secondsTranscribed, setSecondsTranscribed] = useState(0)
    const [wordsBleeped, setWordsBleeped] = useState(0)
    const [filesUploaded, setFilesUploaded] = useState(0)
    const [metricsLoading, setMetricsLoading] = useState(true)

    const fetchUsage = () => {
        usageService.getUsage(organizationId, selectedTimeframe).then(data => {
            if (data) {
                setSecondsTranscribed(data.seconds_transcribed)
                setWordsBleeped(data.words_bleeped)
                setFilesUploaded(data.files_uploaded)
            }

            setMetricsLoading(false)
        })
    }

    const timeframes = ['This Month', 'Last Month', 'This Year']

    const switchTimeframe = (timeframe) => {
        setSelectedTimeframe(timeframe)
        setMetricsLoading(true)
    }

    useEffect(() => {
        if (organizationId) {
            fetchUsage()
        }
    }, [organizationId, selectedTimeframe])

    return (
        <Box width={'100%'}>
            <Menu>
                <MenuButton size={'sm'} bg={'none'} as={Button} rightIcon={<MdArrowDropDown />}>
                    {selectedTimeframe}
                </MenuButton>
                <MenuList size={'sm'} bg={'navy.800'}>
                    {timeframes.map((timeframe, index) => {
                        return (<MenuItem key={`timeframe-${index}`} onClick={() => { switchTimeframe(timeframe) }}>{timeframe}</MenuItem>)
                    })}
                </MenuList>
            </Menu>
            <SimpleGrid columns={{ base: 1, md: 3 }} gap='10px' mt={2} mb={5}>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='50px'
                            h='50px'
                            bg={'brand.600'}
                            icon={
                                <Icon w='32px' h='32px' as={MdAttachFile} />
                            }
                        />
                    }
                    name='Files uploaded'
                    loading={metricsLoading}
                    value={filesUploaded}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='50px'
                            h='50px'
                            bg={'brand.600'}
                            icon={
                                <Icon w='32px' h='32px' as={MdAccessTime} />
                            }
                        />
                    }
                    name='Audio transcribed'
                    loading={metricsLoading}
                    value={helpers.getTimeStamp(secondsTranscribed, true)}
                />

                <MiniStatistics
                    startContent={
                        <IconBox
                            w='50px'
                            h='50px'
                            bg={'brand.600'}
                            icon={
                                <Icon w='25px' h='25px' as={SlSpeech} />
                            }
                        />
                    }
                    name='Words bleeped'
                    loading={metricsLoading}
                    value={wordsBleeped}
                />
            </SimpleGrid>
        </Box >
    )
}

export default Metrics
