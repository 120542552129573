
import { supabase } from 'supabase'

async function createRecording(file, organizationId) {
    const { data: recordingData, error } = await supabase
        .from('recordings')
        .insert([
            {
                organization_id: organizationId,
                name: file.name,
                status: 'new'
            }
        ]).select().single()

    if (error) {
        console.log('error', error)
        return
    }

    const { data: uploadData, error: uploadError } = await supabase
        .storage
        .from('recordings')
        .upload(`${organizationId}/${recordingData.id}.wav`, file, {
            cacheControl: '3600',
            upsert: false
        })

    if (uploadError) {
        console.log(error)
        updateRecording(recordingData.id, { status: 'error' })

    }
    else {
        updateRecording(recordingData.id, {
            raw_file: `${organizationId}/${recordingData.id}.wav`,

        })
        await startTranscribe(recordingData.id)
    }

    return recordingData

}

async function getRecording(id) {
    const { data, error } = await supabase
        .from('recordings')
        .select('*')
        .eq('id', id).single()

    if (error) {
        console.log('error', error)
        return
    }

    return data
}

async function getRecordingFileUrl(filePath) {
    const { data, error } = await supabase
        .storage
        .from('recordings')
        .createSignedUrl(filePath, 1800)

    if (error) {
        console.log(error)
        return
    }

    return data.signedUrl
}

async function updateRecording(id, recording) {
    const { data, error } = await supabase
        .from('recordings')
        .update({ ...recording })
        .eq('id', id)
        .select().single()

    if (error) {
        console.log('error', error)
        return
    }

    return data
}

async function startTranscribe(recordingId) {
    //trigger supabase function
    const { data, error } = await supabase.functions.invoke('transcribe', {
        body: { recordingId }
    })

    if (error) {
        console.log('error', error)
        return
    }
}

async function getTranscription(recordingId) {
    const { data, error } = await supabase
        .from('transcriptions')
        .select('*')
        .eq('recording_id', recordingId).single()

    if (error) {
        console.log('error', error)
        return
    }

    return data
}

async function bleepRecording(recordingId) {
    const { error } = await supabase.functions.invoke('bleep', {
        body: { recordingId }
    })

    if (error) {
        console.log('error', error)
        return
    }
}

async function searchRecordings(query) {
    const { data, error } = await supabase
        .from('transcriptions')
        .select('recordings(*)')
        .textSearch('fts|name', query, {
            config: 'english',
            type: 'phrase'
        })

    if (error) {
        console.log('error', error)
    }

    var returnData = data.map((transcription) => {
        if (transcription.recordings && transcription.recordings !== undefined) {
            return transcription.recordings
        }
    })

    //also search recordings table on the name field
    const { data: recordingData, error: recordingError } = await supabase
        .from('recordings')
        .select('*')
        .textSearch('name', query, {
            config: 'english',
            type: 'phrase'
        })

    if (recordingError) {
        console.log('error', recordingError)
    }

    if (recordingData && recordingData.length > 0) {
        //flag data as title match
        recordingData.forEach((recording) => {
            recording.titleMatch = true
        })

        returnData = returnData.concat(recordingData)
    }

    return returnData.filter((recording) => recording !== undefined)

}
export default { createRecording, getRecording, getRecordingFileUrl, updateRecording, startTranscribe, getTranscription, bleepRecording, searchRecordings }
