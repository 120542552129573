import React, { useEffect, useState } from "react"
import "assets/css/App.css"
import { BrowserRouter, Route, Switch } from "react-router-dom"
import AuthLayout from "layouts/auth"
import AdminLayout from "layouts/admin"
import { ChakraProvider } from "@chakra-ui/react"
import theme from "theme/theme"
import { supabase } from 'supabase'
import { useStore } from 'store'
import ScrollToTop from "components/scroll/ScrollToTop"

export default function App() {
    const { setSession, session } = useStore()
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        try {
            supabase.auth.getSession().then(({ data: { session } }) => {
                setSession(session)
                setLoaded(true)
            })

            supabase.auth.onAuthStateChange((_event, session) => {
                setSession(session)
            })
        } catch (error) {
            console.log('error in App.useEffect', error)
        }
    }, [])
    return (
        <ChakraProvider theme={theme} >
            <React.StrictMode>
                {loaded && (
                    <>
                        <BrowserRouter>
                            <Switch>
                                <Route path={`*`} component={session ? AdminLayout : AuthLayout} />
                            </Switch>
                            <ScrollToTop />
                        </BrowserRouter>
                    </>)}
            </React.StrictMode>
        </ChakraProvider >
    )
}
