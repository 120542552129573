import { Alert, AlertIcon, Box, SimpleGrid, Button, Text, Spinner, Center, Flex, useColorModeValue } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import { useStore } from "store"
import { useParams } from 'react-router-dom'
import Card from "components/card/Card.js"
import recordingService from "services/recordingService"
import { HSeparator } from "components/separator/Separator"
import Transcription from "components/transcription"
import { MdPictureAsPdf } from "react-icons/md"
import AudioPlayer from "components/audioPlayer"
import { NavLink } from "react-router-dom/cjs/react-router-dom.min"
import InputField from "components/fields/InputField"

export default function RecordingDetails() {
  const { id } = useParams()
  const { addAlert } = useStore()

  const [recording, setRecording] = useState(null)
  const [status, setStatus] = useState(null)
  const [audioFileUrl, setAudioFileUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [isDownloading, setIsDownloading] = useState(false)
  const [recordingName, setRecordingName] = useState(null)

  const textColor = useColorModeValue("navy.700", "secondaryGray.500")

  const audioPlayer = React.createRef()
  const [currentAudioTimestamp, setCurrentAudioTimestamp] = useState(0)

  const fetchFile = async (filePath) => {
    if (filePath) {
      const url = await recordingService.getRecordingFileUrl(filePath)
      if (url) {
        setAudioFileUrl(url)
      }
    }
  }

  const fetchRecording = async () => {
    setLoading(true)
    const data = await recordingService.getRecording(id)

    if (data) {
      setRecording(data)
      setStatus(data.status)
      setRecordingName(data.name)

      switch (data.status) {
        case 'transcribed':
        case 'new':
          //fetch raw unredacted file
          await fetchFile(data.raw_file)
          break
        case 'finished':
          //fetch redacted file
          await fetchFile(data.redacted_file)
          break
      }
    }

    setLoading(false)
  }

  const handleRecordingNameSave = async () => {
    if (recordingName !== recording.name) {
      await recordingService.updateRecording(id, { name: recordingName })
      addAlert({ status: 'success', message: 'Recording name updated successfully' })
    }
  }

  const handleSelectedWordsChanged = async (words) => {
    await recordingService.updateRecording(id, { words_to_bleep: words })
  }

  const handleStartBleepin = async () => {
    recordingService.bleepRecording(id)
    setStatus('bleeping')
    await recordingService.updateRecording(id, { status: 'bleeping' })
    pollRecording()
  }
  const pollRecording = async () => {
    const data = await recordingService.getRecording(id)
    if (data) {
      setRecording(data)
      if (data.status == "bleeping") {
        setTimeout(() => {
          pollRecording()
        }, 5000)
      }
      else if (data.status == "finished") {
        fetchRecording()
      }
    }
  }

  const seekTo = (time) => {
    let audio = audioPlayer.current.audioEl.current

    audio.currentTime = time
    if (audio.paused) {
      audio.play()
    }
  }

  const handleTick = (time) => {
    setCurrentAudioTimestamp(time)
  }

  const handleTranscriptionLoaded = () => {
    setStatus('transcribed')
  }

  const handleDownloadClick = async () => {
    setIsDownloading(true)
    await fetch(audioFileUrl)
      .then(resp => resp.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        // the filename you want
        a.download = `${recordingName}-bleepd.wav`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)

        setIsDownloading(false)
      })
      .catch(() => console.log('Failed to download'))
  }

  useEffect(() => {
    if (id) {
      fetchRecording()
    }
  }, [id])

  return (
    <Box>
      {status === 'finished' && (<Box mb={5}>
        <Alert status={'success'} variant={'subtle'}><AlertIcon />
          <Flex width={'100%'} alignItems={'center'} flexDir={{ base: 'column', md: 'row' }}>
            <Text>This recording has been successfully bleeped.</Text>
            <Button mt={{ base: 1, md: 0 }} ml={'auto'} width={{ base: '100%', md: '200px' }} isLoading={isDownloading} onClick={handleDownloadClick} variant={'brand'} size={'md'}>Download Recording</Button>
          </Flex>
        </Alert>
      </Box>)}
      <Card minHeight={'200px'}>
        {loading && <Spinner margin={'auto'}></Spinner>}

        {!loading && (['new', 'transcribed', 'finished'].indexOf(status) > -1) && (<Box>

          <InputField
            placeholder={'Recording Name'}
            width={'100%'}
            mb={{ base: "20px", md: "24px" }}
            value={recordingName}
            onChange={(e) => setRecordingName(e.target.value)}
            onBlur={handleRecordingNameSave} />
          {
            audioFileUrl ? (
              <AudioPlayer src={audioFileUrl}
                audioRef={audioPlayer}
                listenInterval={500}
                onTick={handleTick} />) :
              <Center >
                <Text background={'navy.600'} paddingY={'5px'} paddingX={'10px'} borderRadius={'20px'}>No audio file found</Text>
              </Center>
          }

          <SimpleGrid
            mt={3}
            mb='20px'
            columns={{ base: 1 }}
            spacing={{ base: "10px", xl: "10px" }}>
            {status !== 'finished' && (<Text fontSize={'sm'} paddingY={2} paddingLeft={2} color={textColor}>Review the words that will be bleeped from your recording below. You can toggle whether a word will be bleeped by clicking on it.</Text>)}
            <Transcription recordingId={id}
              defaultSelectedWords={recording.words_to_bleep}
              currentAudioTimestamp={currentAudioTimestamp}
              handleTimestampClick={seekTo}
              onTranscriptionLoaded={handleTranscriptionLoaded}
              onSelectedWordsChanged={handleSelectedWordsChanged}
              readonly={status === 'finished'}>
            </Transcription>

          </SimpleGrid>
          <HSeparator mb={5} />
          <Flex flexDir={{ base: 'column', md: 'row' }} justifyContent={'right'} mb={5}>
            {(status === 'transcribed' || status === 'finished') && (
              <Button leftIcon={<MdPictureAsPdf />} variant={'light'} size={'md'}>Export Words to Bleep</Button>
            )}
            {status === 'transcribed' && (
              <Button onClick={handleStartBleepin} ml={{ base: 0, md: 2 }} mt={{ base: 3, md: 0 }} variant={'brand'} size={'md'}>Bleep Recording</Button>
            )}
          </Flex>

        </Box>)
        }

        {
          !loading && status === 'error' && (
            <Box width={'100%'} height={'100%'} margin={'auto'} textAlign={'center'}>
              <Text fontSize={'xl'} color={textColor}>There was an error processing your recording.</Text>
              <Text fontSize={'sm'} color={textColor}>Please try again.</Text>
              <NavLink to={'/dashboard'} >
                <Button variant={'brand'} mt={5} size={'md'}>Go Home</Button>
              </NavLink>
            </Box>)
        }

        {
          !loading && status === 'bleeping' && (
            <Box textAlign={"center"} margin={"auto"}>
              <Text
                margin={"auto"}
                fontSize={"sm"}
                marginTop={5}
                color={"#a6abaf"}
              >
                Your recording is being bleeped...
              </Text>
              <Box>
                <Spinner mt={3} />
              </Box>
            </Box>)
        }
      </Card >
    </Box >
  )
}
