import {
  Box,
  Flex,
  Table,
  Button,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner
} from "@chakra-ui/react"
import React, { useMemo } from "react"

import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table"

import Card from "components/card/Card"
import { MdCheckCircle, MdPending, MdOutlineError, MdTextSnippet } from "react-icons/md"
import { HSeparator } from "components/separator/Separator"
import helpers from 'utils/helpers'
import Pagination from "./Pagination"

export default function DynamicTable(props) {
  const { columnsData, rowLimit, tableData, title, subtitle, isLoading, emptyMessage, defaultSort, onViewClick } = props

  const columns = useMemo(() => columnsData, [columnsData])
  const data = useMemo(() => tableData, [tableData])
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: [
          {
            id: defaultSort || 'created_at',
            desc: true
          }
        ],
        pageSize: rowLimit ?? 100
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = tableInstance

  const textColor = useColorModeValue("secondaryGray.900", "white")
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100")
  const headerTextColor = useColorModeValue('secondaryGray.900', 'gray.400')
  const subtitleColor = useColorModeValue('secondaryGray.900', 'gray.400')

  const paginationProps = {
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    pageIndex
  }

  const iconColor = (status) => {
    switch (status) {
      case 'finished':
      case 'Paid':
        return 'green.500'
      case 'finished':
        return 'green.500'
      case 'error':
        return 'red.500'
      default:
        return 'gray.500'
    }
  }

  const getIcon = (status) => {
    switch (status) {
      case 'finished':
      case 'Paid':
        return MdCheckCircle
      case 'error':
        return MdOutlineError
      case 'transcribed':
        return MdTextSnippet
      default:
        return MdPending
    }
  }
  const renderStatusCell = (cell) => {
    return (<Flex align='center'>
      <Icon
        w='24px'
        h='24px'
        me='5px'
        color={iconColor(cell.value)}
        as={getIcon(cell.value)} />
      <Text color={textColor} fontSize='sm'>
        {cell.value}
      </Text>
    </Flex>
    )
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      pt='30px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px={5} justify='space-between' mb='15px' align='center'>
        <Text
          color={textColor}
          fontSize='xl'
          fontWeight='bold'
          lineHeight='100%'>
          {title}
        </Text>
      </Flex>

      {subtitle && (
        <Flex ml={2} px={5} justify='space-between' mb='20px' align='center'>
          <Text
            color={subtitleColor}
            fontSize='md'
            lineHeight='100%'>
            {subtitle}
          </Text>
        </Flex>
      )}
      <HSeparator mt={1}></HSeparator>
      {isLoading ? <Spinner mx={'auto'} mt={25}></Spinner> : (
        <>
          <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
            <Thead>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe='10px'
                      key={index}
                      borderColor={borderColor}>
                      <Flex
                        justify='space-between'
                        align='center'
                        textTransform={'none'}
                        fontSize={{ sm: "10px", lg: "14px" }}
                        color={headerTextColor}>
                        {column.render("Header")}
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row)
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = ""
                      if (cell.column.type === "status") {
                        data = (
                          renderStatusCell(cell)
                        )
                      } else if (cell.column.type === "date") {
                        let date = new Date(cell.value)
                        data = (
                          <Text color={textColor} fontSize='sm'>
                            {date && date.toLocaleString("en-US")}
                          </Text>
                        )
                      } else if (cell.column.type === "duration") {
                        data = (
                          <Text color={textColor} fontSize='sm'>
                            {cell.value ? `${helpers.getTimeStamp(cell.value, true)}` : '(no data)'}
                          </Text>
                        )
                      }
                      else if (cell.column.type === "currency") {
                        data = (
                          <Text color={textColor} fontSize='sm'>
                            {cell.value ? `${helpers.formatCurrency(cell.value)}` : '(no data)'}
                          </Text>
                        )
                      }
                      else if (cell.column.type === "actions") {
                        data = (
                          <Box>
                            <Button onClick={() => { onViewClick(cell) }} size={'sm'}>View</Button>
                          </Box>

                        )
                      } else if (cell.column.type === "progress") {
                        data = (
                          <Flex align='center'>
                            <Progress
                              variant='table'
                              colorScheme='brandScheme'
                              h='8px'
                              w='108px'
                              value={cell.value}
                            />
                          </Flex>
                        )
                      }
                      else {
                        data = (
                          <Text color={textColor} fontSize='sm'>
                            {cell.value}
                          </Text>
                        )
                      }
                      return (
                        <Td
                          {...cell.getCellProps()}
                          key={index}
                          fontSize={{ sm: "14px" }}
                          minW={{ sm: "150px", md: "200px", lg: "auto" }}
                          borderColor='transparent'>
                          {data}
                        </Td>
                      )
                    })}
                  </Tr>
                )
              })}
              {page.length === 0 && (<Tr><Td colSpan={columns.length}><Text width={'fit-content'} marginX={'auto'}>{emptyMessage || 'no data'}</Text></Td></Tr>)}
            </Tbody>
          </Table>
          <Pagination {...paginationProps} />
        </>
      )
      }

    </Card >
  )
}
