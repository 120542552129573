// Chakra imports
import { Button, Flex, Input, useColorModeValue } from "@chakra-ui/react"
// Assets
import React from "react"
import { useDropzone } from "react-dropzone"

function Dropzone(props) {
  const { content, onChange, ...rest } = props
  const { getRootProps, getInputProps } = useDropzone({ onDrop: onChange })
  const bg = useColorModeValue("#fff6f4", "navy.700")
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100")

  return (
    <Flex
      align='center'
      justify='center'
      bg={bg}
      border='1px dashed'
      borderColor={borderColor}
      borderRadius='16px'
      cursor='pointer'
      {...getRootProps({ className: "dropzone" })}
      {...rest}>
      <Input variant='main' {...getInputProps()} />
      <Button variant='no-effects' >{content}</Button>
    </Flex>
  )
}

export default Dropzone
