import { Box, SimpleGrid } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import DynamicTable from "components/table"
import { useStore } from "store"
import { useHistory } from "react-router-dom"
import { useParams } from 'react-router-dom'
import recordingService from "services/recordingService"

export default function Search() {
  const { query } = useParams()
  const { userProfile } = useStore()
  const [files, setFiles] = useState([])
  const [filesLoading, setFilesLoading] = useState(true)
  const history = useHistory()

  const tableHeaders = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Date",
      type: 'date',
      accessor: "created_at",
    },
    {
      Header: "Duration",
      type: 'duration',
      accessor: "duration",
    },
    {
      Header: "Status",
      type: 'status',
      accessor: "status",
    },
    {
      Header: "",
      type: 'actions',
      accessor: "id",
    },
  ]

  const fetchFiles = async () => {
    const data = await recordingService.searchRecordings(query)
    if (data) {
      setFiles(data)
      setFilesLoading(false)
    }

  }
  const onViewClick = (cell) => {
    if (cell.row.original.titleMatch) {
      history.push(`/recording/${cell.value}`)
    }
    else {
      history.push({
        pathname: `/recording/${cell.value}`,
        search: `?search=${query}`
      })
    }
  }

  useEffect(() => {
    if (userProfile) {
      fetchFiles()
    }
  }, [userProfile])

  useEffect(() => {
    if (query) {
      setFilesLoading(true)
      fetchFiles()
    }
  }, [query])

  return (
    <Box>
      <SimpleGrid
        mb='20px'
        columns={{ base: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <DynamicTable columnsData={tableHeaders}
          rowLimit={10}
          tableData={files} title={`Search Results for "${query}"`}
          subtitle={`These recordings match your search query. Click view to view the full recording details.`}
          onViewClick={onViewClick}
          emptyMessage={'No results found'}
          isLoading={filesLoading} />
      </SimpleGrid>
    </Box>
  )
}
