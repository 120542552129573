import {
  Avatar,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import React from "react"
import { supabase } from "supabase"
import { useStore } from 'store'
import PlanBadge from "components/planBadge"
import logo from "assets/img/logo.png"
import NotificationBell from "./NotificationBell"
import NavSearch from "./NavSearch"

export default function Navbar(props) {
  let menuBg = useColorModeValue("white", "navy.800")
  const menuColor = useColorModeValue('secondaryGray.400', 'navy.800')
  const { userProfile } = useStore()

  const logout = async () => {
    await supabase.auth.signOut()
  }

  return (
    <Flex
      paddingX={{ base: "20px", md: "30px" }}
      paddingY={{ base: "10px", md: "10px" }}
      justifyContent='space-beweteen'
      gap={3}
      borderBottom={'1px'}
      borderColor={useColorModeValue("secondaryGray.400", "#212d37")}
      background={menuColor} >

      <NavLink to='/dashboard'>
        <Image maxW={'unset'} pl={4} src={logo} h={{ base: '30px' }} mt={1} />
        <Text minWidth={'65px'} fontWeight={'bold'}>bleep-it</Text>
      </NavLink>
      <NavSearch />
      <PlanBadge />
      <NotificationBell />
      <Menu>
        <MenuButton p='0px'>
          <Avatar
            _hover={{ cursor: "pointer" }}
            color='white'
            name={`${userProfile?.first_name} ${userProfile?.last_name}`}
            bg='brand.500'
            size='sm'
            fontWeight={'bold'}
            w='40px'
            h='40px'
          />
        </MenuButton>
        <MenuList
          p='0px'
          mt='0px'
          borderRadius='20px'
          bg={menuBg}
          border='none'>
          <Flex flexDirection='column' p='10px'>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius='8px'
              px='14px'>
              <NavLink to='/manage-subscription'>
                <Text fontSize='md'>Manage Subscription</Text>
              </NavLink>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              borderRadius='8px'
              px='14px'>
              <NavLink to='/account-settings'>
                <Text fontSize='md'>Account Settings</Text>
              </NavLink>
            </MenuItem>
            <MenuItem
              _hover={{ bg: "none" }}
              _focus={{ bg: "none" }}
              color='red.400'
              px='14px'
              onClick={logout}
            >
              <Text fontSize='md'>Log Out</Text>
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu>

    </Flex >
  )
}