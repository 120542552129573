// Chakra imports
import {
    Badge,
    Box,
    Button,
    Flex,
    Icon,
    Text,
    SimpleGrid,
    useDisclosure
} from "@chakra-ui/react"
import React, { useState, useEffect } from "react"
import { MdAvTimer } from "react-icons/md"

import { FaPodcast, FaVideo } from "react-icons/fa"

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from "@chakra-ui/react"
import { HSeparator } from "components/separator/Separator"
import { useStore } from "store"

export default function SubscriptionModal(props) {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const { subscription, organizationId, fetchSubscription } = useStore()
    const [plans, setPlans] = useState([
        {
            name: 'bleep-it shorts',
            productId: 43408,
            icon: FaPodcast,
            description: 'tailored for audio/video creators creating many short videos or clips.',
            badgeText: 'ideal for podcasters/YouTube creators',
            price: '$50',
            duration: 'per month',
            features: ['bleep up to 100 recordings', 'max upload duration of 2 minutes']
        },
        {
            name: 'bleep-it creator',
            icon: FaVideo,
            productId: 43408,
            description: 'tailored for audio/video creators with longer durations.',
            badgeText: 'ideal for TikTok/YouTube Shorts creators',
            price: '$50',
            duration: 'per month',
            features: ['bleep up to 10 recordings', 'max upload duration of 2 hours']
        },
    ])

    const openCheckout = (productId) => {
        onClose()
        window.Paddle.Checkout.open({
            product: productId,
            email: 'gtcowgill@gmail.com',
            customData: {
                organizationId
            },
            closeCallback: handleCheckoutClosed
        })
    }


    const handleCheckoutClosed = (data) => {
        fetchSubscription()
    }

    const openManageSubscription = () => {
        window.Paddle.Checkout.open({
            override: subscription.update_url,
            closeCallback: handleCheckoutClosed
        })
    }

    const openCancelSubscription = () => {
        window.Paddle.Checkout.open({
            override: subscription.cancel_url,
            closeCallback: handleCheckoutClosed
        })
    }

    useEffect(() => {

    }, [])

    return (
        <>
            {subscription && (
                <Box width={'100%'} marginX={'auto'}>
                    <Button width={'100%'} fontWeight={'bold'} onClick={openManageSubscription}>Update Card</Button>
                    <br />
                    <Button width={'100%'} fontWeight={'bold'} variant={'brand'} marginTop={5} marginX={'auto'} onClick={openCancelSubscription}>Cancel Subscription</Button>
                </Box>
            )}
            {!subscription && (
                <Box width={'100%'} marginX={'auto'}>
                    <Button colorScheme={'green'} width={'100%'} fontWeight={'bold'} fontSize={'lg'} onClick={onOpen}>
                        Upgrade Account
                    </Button>
                </Box>
            )}

            <Modal isOpen={isOpen} onClose={onClose} size={'2xl'}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader fontSize={'2xl'} textAlign={'center'}>Upgrade Account</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SimpleGrid
                            mb='20px'
                            textAlign={'center'}
                            columns={{ base: 1, lg: 2 }}
                            spacing={{ base: "20px", xl: "20px" }}>

                            {plans.map((plan, index) => {
                                return (
                                    <Flex
                                        key={index}
                                        onClick={() => openCheckout(plan.productId)}
                                        flex={1}
                                        flexDirection={'column'}
                                        background={'navy.700'}
                                        padding={5}
                                        borderWidth={1}
                                        borderRadius={3}
                                        borderColor={'navy.600'}
                                        height={'100%'}
                                        cursor={'pointer'}
                                        _hover={{ borderBottomColor: 'green.200' }}>
                                        <Box mb={2}>
                                            <Icon color={'brand.500'} fontSize={'5xl'} as={plan.icon}></Icon>
                                        </Box>
                                        <Text fontSize='2xl' fontWeight='bold'>{plan.name}</Text>
                                        <Text fontSize={'sm'}>{plan.description}</Text>
                                        <HSeparator mt={2} mb={3} />

                                        <Box>
                                            {plan.features.map((feature, fIndex) => {
                                                return (
                                                    <Flex key={`feature-${fIndex}`} justifyContent={'center'} pb={1} mb={2} borderBottomWidth={1} borderBottomColor={'gray.600'}>
                                                        <Icon mr={1} mt={1} as={MdAvTimer}></Icon>
                                                        <Text fontSize='md'>{feature}</Text>
                                                    </Flex>
                                                )
                                            })}
                                        </Box>
                                        <Badge mt={5} variant="solid" colorScheme="green" textTransform={'none'} fontSize={'sm'}>{plan?.badgeText}</Badge>
                                        <Box mt={'auto'} width={'100%'} pt={5}>
                                            <Text fontSize='2xl' mb={3} fontWeight='bold'>{plan.price}
                                                <Text fontWeight={'normal'} fontSize={'sm'}>{plan.duration}</Text></Text>
                                            <Button width={'100%'} colorScheme={'green'}>
                                                Upgrade
                                            </Button>
                                        </Box>
                                    </Flex>
                                )
                            })}
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>

    )
}
