// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    Spinner,
    useColorModeValue,
} from "@chakra-ui/react"
import Card from "components/card/Card.js"
import React, { useState } from "react"
import { MdUpload, MdCheckCircle } from "react-icons/md"
import Dropzone from "./Dropzone"
import { useStore } from 'store'

export default function Upload(props) {
    const { used, total, onSubmit, onChange, isUploading, ...rest } = props
    const { addAlert } = useStore()
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white")
    const brandColor = useColorModeValue("brand.500", "white")
    const textColorSecondary = "gray.400"
    const [fileLabel, setFileLabel] = useState("click or drop file here")
    const [fileSelected, setFileSelected] = useState(false)

    const handleFileChanged = (files) => {
        if (files && files[0] && files[0].name.toLowerCase().indexOf('.wav') !== -1) {
            onChange(files)
            setFileLabel(files[0].name)
            setFileSelected(true)
        }
        else {
            addAlert({
                status: 'error', message: 'please select a valid .WAV file'
            })
        }
    }

    return (
        <Card {...rest} mb='20px' align='center' p='20px'>
            {isUploading ? <Spinner margin={'auto'} /> : (


                <Flex flexDir={{ base: 'column', lg: 'row' }} >
                    <Dropzone
                        grow={1}
                        maxW={{ base: "100%", lg: "50%" }}
                        minH={{ base: "175px" }}
                        onChange={handleFileChanged}
                        content={
                            <Box>
                                {!fileSelected && (<Icon as={MdUpload} w='80px' h='80px' color={brandColor} />)}
                                {fileSelected && (<Icon as={MdCheckCircle} w='80px' h='80px' color={'green.500'} />)}
                                <Flex justify='center' mx='auto' mb='12px'>
                                    <Text fontSize='xl' fontWeight='700' color={brandColor}>
                                        {fileLabel}
                                    </Text>
                                </Flex>
                                {!fileSelected && (<Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                    only .WAV files are supported.
                                </Text>)}

                                {fileSelected && (<Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                                    file ready to upload. press start bleepin to begin.
                                </Text>)}
                            </Box>
                        }
                    />
                    <Box ml={{ base: '0', lg: '25px' }}>
                        <Text
                            color={textColorPrimary}
                            fontWeight='bold'
                            textAlign='start'
                            fontSize='2xl'
                            mt={{ base: "20px", "2xl": "50px" }}>
                            Clean up your recordings.
                        </Text>
                        <Text
                            color={textColorSecondary}
                            fontSize='md'
                            textAlign='start'>
                            Upload a new file to begin.
                        </Text>
                        <Button
                            me='100%'
                            onClick={onSubmit}
                            mb='50px'
                            w='140px'
                            minW='140px'
                            disabled={!fileSelected}
                            mt={{ base: 3, "2xl": 5 }}
                            variant='brand'
                            fontWeight='500'>
                            Upload File
                        </Button>
                    </Box>
                </Flex>)}
        </Card >
    )
}
