import { mode } from "@chakra-ui/theme-tools"
export const modalStyles = {
  components: {
    Modal: {
      baseStyle: {
        dialog: {
          background: 'navy.800',
        }
      }
    },
  },
}
