import { Button, Box, Center, SimpleGrid, Flex, Text, useColorModeValue } from "@chakra-ui/react"
import React, { useEffect, useState } from "react"
import Card from "components/card/Card.js"
import { HSeparator } from "components/separator/Separator"
import { useStore } from "store"
import DynamicTable from "components/table"
import paymentService from "services/paymentService"
import helpers from "utils/helpers"
import SubscriptionModal from "components/subscriptionModal"

export default function ManageSubscription() {
  const { subscription, organizationId } = useStore()
  const textColor = useColorModeValue("secondaryGray.900", "gray.400")
  const [paymentsLoading, setPaymentsLoading] = useState(false)
  const [payments, setPayments] = useState([])
  const [isOpen, setIsOpen] = useState(true)

  const invoiceTableColumns = [
    {
      Header: "ID",
      accessor: "provider_id",
    },
    {
      Header: "Created Date",
      type: "date",
      accessor: "created_at",
    },
    {
      Header: "Amount",
      type: "currency",
      accessor: "amount",
    },
    {
      Header: "Status",
      type: 'status',
      accessor: "status",
    },
    {
      Header: "",
      type: 'actions',
      accessor: "receipt_url",
    },
  ]

  const fetchPayments = async () => {
    if (organizationId) {
      setPaymentsLoading(true)

      var paymentData = await paymentService.getPayments(organizationId)
      setPayments(paymentData)
      setPaymentsLoading(false)
    }
  }

  useEffect(() => {
    fetchPayments()
  }, [organizationId, subscription])

  const onViewClick = (cell) => {
    window.open(cell.value, '_blank', 'noreferrer')
  }

  return (
    <Box>
      <Card align='left' mb={5} direction='column' w='100%'>
        <Text
          me='auto'
          mt={1}
          color={useColorModeValue('secondaryGray.900', 'white')}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Manage Subscription
        </Text>
        <HSeparator mt={5} mb={2}></HSeparator>
        <SimpleGrid
          mb='20px'
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <Box align={'left'} padding={2}>
            <SimpleGrid columns={{ base: 1, lg: 2 }} gap={'20px'} spacing={{ base: "20px", xl: "20px" }}>
              <Box>
                <Text color={textColor} fontWeight={'bold'} fontSize={'lg'}>Plan Name</Text>
                <Text fontSize={'lg'}>{subscription?.product_name || "Free Plan"}</Text>
              </Box>
              <Box>
                <Text color={textColor} fontWeight={'bold'} fontSize={'lg'}>Uploads Per Month</Text>
                <Text fontSize={'lg'}>{subscription?.hours_per_month || "1"}</Text>
              </Box>
              <Box>
                <Text color={textColor} fontWeight={'bold'} fontSize={'lg'}>Payment Amount</Text>
                <Text fontSize={'lg'}>{subscription?.next_payment_amount ? helpers.formatCurrency(subscription?.next_payment_amount) : "n/a"}</Text>
              </Box>
              <Box>
                <Text color={textColor} fontWeight={'bold'} fontSize={'lg'}>Next Payment Due</Text>
                <Text fontSize={'lg'}>{subscription?.next_payment_date ? subscription?.next_payment_date : "n/a"}</Text>
              </Box>
            </SimpleGrid>
          </Box>
          <Flex align='center' w={{ base: "100%", '2xl': "50%" }} px='15px' py='10px'>
            <SubscriptionModal />
          </Flex>
        </SimpleGrid>
      </Card>
      <DynamicTable
        title='Invoices'
        columnsData={invoiceTableColumns}
        emptyMessage='no invoices'
        tableData={payments || []}
        rowLimit={5}
        onViewClick={onViewClick}
        isLoading={paymentsLoading}
      />
    </Box >
  )
}
