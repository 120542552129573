import { mode } from "@chakra-ui/theme-tools"
export const globalStyles = {
  colors: {
    brand: {
      100: "#fff2f0",
      200: "#ffdbd6",
      300: "#ffc5bd",
      400: "#ffafa3",
      500: "#ff8370",
      600: "#ff573d",
      700: "#ff4124",
      800: "#ff2b0a",
      900: "#d61c00",
    },
    brandScheme: {
      100: "#fff2f0",
      200: "#ffdbd6",
      300: "#ffc5bd",
      400: "#ffafa3",
      500: "#ff8370",
      600: "#ff573d",
      700: "#ff4124",
      800: "#ff2b0a",
      900: "#d61c00",
    },
    brandTabs: {
      100: "#fff2f0",
      200: "#ffdbd6",
      300: "#ffc5bd",
      400: "#ffafa3",
      500: "#ff8370",
      600: "#ff573d",
      700: "#ff4124",
      800: "#ff2b0a",
      900: "#d61c00",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#96adc1",
      100: "#7694ae",
      200: "#6688a4",
      300: "#597b97",
      400: "#506e87",
      500: "#3D5467",
      600: "#2a3a47",
      700: "#212d37",
      800: "#172027",
      900: "#040607",
    },
    gray: {
      100: "#FAFCFE",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("#dadada", "#0d141a")(props),
        fontFamily: "Montserrat",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "Montserrat",
      },
    }),
  },
}
