import {
  Box,
  Text,
  Flex,
  Button,
  ButtonGroup,
  IconButton,
  useColorModeValue,
  Spinner,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react"
import React, { useState, useEffect, useRef } from "react"
import { VariableSizeList as List } from "react-window"
import helpers from "utils/helpers"
import recordingService from "services/recordingService"
import useWindowSize from "hooks/useWindowSize"
import {
  ChevronRightIcon,
  ChevronLeftIcon
} from "@chakra-ui/icons"

import { useLocation } from 'react-router-dom'

export default function Transcription(props) {
  const {
    recordingId,
    defaultSelectedWords,
    handleTimestampClick,
    currentAudioTimestamp,
    onSelectedWordsChanged,
    onTranscriptionLoaded,
    readonly
  } = props
  const [transcription, setTranscription] = useState(null)
  const [selectedWords, setSelectedWords] = useState(defaultSelectedWords || [])
  const [searchResults, setSearchResults] = useState([])
  const [currentWord, setCurrentWord] = useState(0)
  const [isPollingForTranscription, setIsPollingForTranscription] = useState(false)

  const listRef = React.createRef()
  const containerRef = React.createRef()
  let location = useLocation()
  var initialSearch = new URLSearchParams(location.search).get("search")

  useEffect(() => {
    if (recordingId) {
      fetchTranscription()
    }
  }, [recordingId])

  useEffect(() => {
    if (initialSearch && transcription) {
      searchTranscription(initialSearch)
    }
  }, [transcription])

  useEffect(() => {
    if (selectedWords && onSelectedWordsChanged) {
      onSelectedWordsChanged(selectedWords)
    }
  }, [selectedWords])

  const fetchTranscription = async () => {
    const data = await recordingService.getTranscription(recordingId)
    if (data) {
      await setTranscription(data)

      if (data.status == "processing") {
        setIsPollingForTranscription(true)
        setTimeout(() => {
          fetchTranscription()
        }, 5000)
      }
      else if (data.status == "completed") {
        if (isPollingForTranscription && onTranscriptionLoaded) {
          setIsPollingForTranscription(false)
          onTranscriptionLoaded(data)
        }
      }
    }
  }

  const scrollWords = (wordCollection, direction) => {
    var index = 0

    if (direction === "next" && wordCollection.length > currentWord + 1) {
      index = currentWord + 1
    }

    if (direction === "prev") {
      if (currentWord - 1 >= 0) {
        index = currentWord - 1
      } else {
        index = wordCollection.length - 1
      }
    }

    setCurrentWord(index)
    var word = wordCollection[index]
    if (word) listRef.current.scrollToItem(word.sIndex, "start")
  }

  const handleWordClick = (sIndex, wIndex, word) => {
    if (!readonly) {
      let newWords = [...selectedWords]

      if (
        newWords.filter((s) => s.sIndex == sIndex && s.wIndex == wIndex).length >
        0
      ) {
        newWords.splice(
          newWords.findIndex((s) => s.sIndex == sIndex && s.wIndex == wIndex),
          1
        )
      } else {
        newWords.push({
          sIndex: sIndex,
          wIndex: wIndex,
          start: word.start,
          end: word.end,
          text: word.text,
        })
      }

      setSelectedWords(newWords)
    }
  }

  const isWordSelected = (sIndex, wIndex) => {
    return (
      selectedWords.filter((s) => s.sIndex == sIndex && s.wIndex == wIndex)
        .length > 0
    )
  }

  const highlightSentence = (sIndex) => {
    if (searchResults.filter((s) => s.sIndex == sIndex).length > 0) {
      return true
    }
    return false
  }

  const isCurrentSentence = (sentence) => {
    return (
      currentAudioTimestamp >= sentence.start / 1000 &&
      currentAudioTimestamp <= sentence.end / 1000
    )
  }

  const searchTranscription = (search) => {
    if (search && search.length > 3 && transcription && transcription.sentences) {
      const indexes = transcription.sentences.reduce(
        (accumulator, current, index) => {
          if (current.text.toLowerCase().includes(search.toLowerCase())) {
            accumulator.push({ sIndex: index })
          }

          return accumulator
        },
        []
      )

      //scroll to first result
      if (indexes.length > 0) {
        listRef.current.scrollToItem(indexes[0].sIndex, "start")
      }

      setSearchResults(indexes)
    } else {
      setSearchResults([])
    }
  }

  const handleSearchEnter = (e) => {
    if (e.key === "Enter") {
      scrollWords(searchResults, "next")
    }
  }

  const windowSize = useWindowSize()
  let rowHeights = []

  const getItemSize = (index) => {
    return rowHeights[index] || 50
  }

  const calcRowHeights = () => {
    let containerWidth = containerRef?.current?.clientWidth
    if (containerWidth) {
      //subtract padding
      containerWidth -= 32

      //line height defaults
      const firstLineHeight = 50
      const subsequentLineHeight = 23

      //calculate number of characters before wrapping to next line
      const charsPerLine = (containerWidth) / 8.5

      rowHeights = (transcription?.sentences || []).map((sentence) => {
        //calculate number of lines for this sentence
        const numLines = Math.ceil(sentence.text.length / charsPerLine)

        return firstLineHeight + ((numLines - 1) * subsequentLineHeight)
      })

      //if list is already rendered then reset it
      if (listRef.current) {
        listRef.current.resetAfterIndex(0)
      }
    }
  }

  //recalculate row heights when window size changes
  useEffect(() => {
    calcRowHeights()
  }, [windowSize])

  //this fires way too often but im confused so im just gonna leave it
  useEffect(() => {
    calcRowHeights()
  }, [containerRef])

  const borderBottomColor = useColorModeValue("gray.200", "#293f50")
  function Sentence({ style, index }) {
    const sentence = transcription?.sentences[index]
    const currentTimeStampColor = useColorModeValue("brand.700", "#FFF")
    const highlightSentenceBg = useColorModeValue('#ffe00042', "rgba(255, 255, 255, 0.1)")
    return (
      <div style={style}>
        <Flex
          justifyContent={"left"}
          borderBottomWidth={"1px"}
          borderColor={borderBottomColor}
          flexDirection="row"
          cursor={"pointer"}
          justify="space-between"
          borderRadius={"5"}
          padding={2}
          id={`sentence-${index}`}
          key={`sentence-${index}`}
        >
          <Box>
            <Text
              fontSize={"xs"}
              paddingTop={"4px"}
              userSelect={"none"}
              title={"Click to play recording from this timestamp"}
              onClick={() => {
                handleTimestampClick(sentence.start / 1000)
              }}
              color={isCurrentSentence(sentence) ? currentTimeStampColor : "secondaryGray.500"}
            >
              {helpers.getTimeStamp(sentence.start)}:
            </Text>
          </Box>
          <Box>
            <Text
              marginX={"7px"}
              style={
                highlightSentence(index)
                  ? {
                    background: highlightSentenceBg,
                    borderRadius: "10px",
                    padding: "3px 7px",
                    marginTop: "-3px",
                  }
                  : {}
              }
              borderRadius={2}
              borderColor={"#FFF"}
            >
              {sentence.words.map((word, wIndex) => {
                return (
                  <Text
                    as={"span"}
                    userSelect={"none"}
                    key={`word-${wIndex}`}
                    onClick={() => {
                      handleWordClick(index, wIndex, word)
                    }}
                    color={
                      isWordSelected(index, wIndex) ? "brand.500" : "inherit"
                    }
                  >
                    {`${word.text} `}
                  </Text>
                )
              })}
            </Text>
          </Box>
        </Flex>
      </div>
    )
  }

  return (
    <>
      <Flex flexDirection={{ base: 'column', md: 'row' }} justifyContent={'space-between'}>
        <InputGroup size="md" maxWidth={'550px'}>
          <Input
            pr="8rem"
            variant="auth"
            fontSize="sm"
            defaultValue={initialSearch || ""}
            onKeyDown={handleSearchEnter}
            onChange={(e) => {
              searchTranscription(e.target.value)
            }}
            placeholder="Search transcription"
          />
          <InputRightElement width="8.5rem">
            <Text color={"secondaryGray.500"} mr={2}>
              {searchResults.length} results
            </Text>
            <ButtonGroup size="sm" isAttached variant="outline">
              <IconButton
                onClick={() => {
                  scrollWords(searchResults, "prev")
                }}
                size={"xs"}
                color={"secondaryGray.500"}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
              <IconButton
                onClick={() => {
                  scrollWords(searchResults, "next")
                }}
                size={"xs"}
                color={"secondaryGray.500"}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </ButtonGroup>
          </InputRightElement>
        </InputGroup>
        <Flex marginTop={3} >
          <Text
            fontSize={"sm"}
            mr={"10px"}
            mt={"2px"}
            mb={"5px"}
            color={"secondaryGray.500"}
          >
            {selectedWords.length} bleeped words
          </Text>
          <ButtonGroup size="sm" isAttached variant="outline">
            <IconButton
              onClick={() => {
                scrollWords(selectedWords, "prev")
              }}
              size={"xs"}
              color={"secondaryGray.500"}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
            <IconButton
              onClick={() => {
                scrollWords(selectedWords, "next")
              }}
              size={"xs"}
              color={"secondaryGray.500"}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </ButtonGroup>
        </Flex>
      </Flex>
      <Box
        background={useColorModeValue("secondaryGray.300", "navy.700")}
        borderRadius={"5"}
        border={"1px solid"}
        borderColor={useColorModeValue('secondaryGray.200', "#212d37")}
        minHeight={transcription?.status === "processing" ? "unset" : "450"}
        textColor={"white.600"}
        paddingX={4}
        ref={containerRef}
        paddingY={3}
      >
        {transcription && transcription.status === "processing" && (
          <Box textAlign={"center"} margin={10}>
            <Text
              margin={"auto"}
              fontSize={"sm"}
              marginTop={10}
              color={"#a6abaf"}
            >
              Transcription in progress...
            </Text>
            <Box>
              <Spinner mt={3} />
            </Box>
          </Box>
        )}

        {transcription && transcription.status === "completed" && (
          <>
            <List
              height={400}
              itemCount={(transcription.sentences || []).length}
              itemSize={getItemSize}
              width={"100%"}
              ref={listRef}
            >
              {Sentence}
            </List>
          </>
        )}
      </Box>
    </>
  )
}
