import {
  Box,
  SimpleGrid
} from "@chakra-ui/react"
import React from "react"
import Upload from "components/upload/Upload"
import DynamicTable from "components/table"
import { useStore } from "store"
import { useEffect, useState } from "react"
import { supabase } from "supabase"
import recordingService from "services/recordingService"
import { useHistory } from "react-router-dom"
import Metrics from "./components/metrics"

export default function Dashboard() {
  const history = useHistory()
  const { userProfile, organizationId, addAlert } = useStore()
  const [files, setFiles] = useState([])
  const [filesLoading, setFilesLoading] = useState(true)
  const [uploadFile, setUploadFile] = useState(null)
  const [uploading, setUploading] = useState(false)

  const recentRecordingsColumns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Created Date",
      type: 'date',
      accessor: "created_at",
    },
    {
      Header: "Duration",
      type: 'duration',
      accessor: "duration"
    },
    {
      Header: "Status",
      type: 'status',
      accessor: "status",
    },
    {
      Header: "",
      type: 'actions',
      accessor: "id",
    },
  ]

  const onViewClick = (cell) => {
    history.push(`/recording/${cell.value}`)
  }

  const handleFileChanged = (files) => {
    setUploadFile(files[0])
  }

  const handleFileSubmit = async () => {
    if (uploadFile) {
      setUploading(true)
      const recording = await recordingService.createRecording(uploadFile, organizationId)

      if (recording && recording.id) {
        history.push(`/recording/${recording.id}`)
      }
    }
    else {
      addAlert({ status: 'error', message: 'Please select a file.' })
    }
  }

  const fetchFiles = async () => {
    const { data, error } = await supabase
      .from('recordings')
      .select('*')
      .eq('organization_id', userProfile.organization_id)

    if (error) {
      console.log(error)
    }
    else {
      setFiles(data)
      setFilesLoading(false)
    }
  }

  useEffect(() => {
    if (userProfile) {
      fetchFiles()
    }
  }, [userProfile])

  return (
    <Box>
      <Box width={'100%'} marginBottom={3}>
        <Metrics />
        <Upload isUploading={uploading} onSubmit={handleFileSubmit} onChange={handleFileChanged} />
        <SimpleGrid columns={1} gap='10px'>
          <DynamicTable
            title='Recent Recordings'
            columnsData={recentRecordingsColumns}
            emptyMessage='no recordings uploaded... yet'
            tableData={files}
            rowLimit={5}
            isLoading={filesLoading}
            onViewClick={onViewClick}
          />
        </SimpleGrid>
      </Box>
    </Box>
  )
}
