import {
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react"
import { MdOutlineSearch } from "react-icons/md"
import { useHistory } from "react-router-dom"
import { useState } from "react"
import { useColorModeValue } from "@chakra-ui/color-mode"

function NavSearch() {
  const history = useHistory()
  const [search, setSearch] = useState(null)

  const textBoxProps = {
    variant: 'auth',
    marginTop: 3,
    fontSize: 'sm',
    fontWeight: '500'
  }

  const iconColor = useColorModeValue('secondaryGray.900', 'gray.400')

  const searchRecordings = () => {
    if (search) {
      history.push(`/search/${search}`)
      setSearch('')
    }
  }

  return (
    <InputGroup size="md">
      <Input
        placeholder={'Search recordings...'}
        onChange={(e) => { setSearch(e.target.value) }}
        value={search}
        onKeyDown={(e) => { if (e.key === 'Enter') { searchRecordings() } }}
        {...textBoxProps} />
      <InputRightElement mt={3}>
        <Icon as={MdOutlineSearch} color={iconColor} cursor={'pointer'} onClick={searchRecordings} />
      </InputRightElement>
    </InputGroup>
  )
}

export default NavSearch
