// Chakra imports
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    Spinner,
    Progress,
    useColorModeValue,
    SimpleGrid
} from "@chakra-ui/react"
import React, { useState, useEffect } from "react"

import { IoPlayCircle, IoPauseCircle } from "react-icons/io5"
import ReactAudioPlayer from 'react-audio-player'
import helpers from 'utils/helpers'

export default function AudioPlayer(props) {
    const { src, audioRef, listenInterval, onTick } = props
    const [isPlaying, setIsPlaying] = useState(false)
    const [timestamp, setTimestamp] = useState(0)
    const [metadata, setMetdata] = useState(null)
    const [duration, setDuration] = useState(0)

    useEffect(() => {
        if (metadata) {
            let audio = audioRef?.current?.audioEl?.current
            if (audio) {
                setDuration(audio.duration)
            }
        }
    }, [metadata])

    const togglePlay = () => {
        let audio = audioRef?.current?.audioEl?.current

        if (audio) {
            if (audio.paused) {
                audio.play()
            }
            else {
                audio.pause()
            }
        }
    }

    const handleOnListen = (time) => {
        setTimestamp(time)
        if (onTick) {
            onTick(time)
        }
    }

    const handleSeekClick = (e) => {
        const x = (e.clientX * duration) / e.currentTarget.offsetWidth
    }

    const seekTo = (time) => {
        let audio = audioRef.current.audioEl.current

        audio.currentTime = time
        if (audio.paused) {
            audio.play()
        }
    }

    return (
        <>
            <Flex
                borderWidth={'1px'}
                borderColor={useColorModeValue('secondaryGray.200', '#212d37')}
                background={useColorModeValue('white', 'navy.700')}
                borderRadius={'5px'}
                padding={4}>
                <Icon onClick={togglePlay} color={useColorModeValue('secondaryGray.500', 'currentcolor')} _hover={{ 'color': 'secondaryGray.600' }} width={'50px'} mr={2} cursor={'pointer'} height={'50px'} as={isPlaying ? IoPauseCircle : IoPlayCircle} />
                <Text minW={'55px'} fontSize={'xs'} color={'secondaryGray.500'} mr={2} mt={'15px'}>{helpers.getTimeStamp(timestamp * 1000)}</Text>
                <Progress onClick={handleSeekClick} size={'sm'} cursor={'pointer'} mt={'20px'} colorScheme={'gray'} width={'100%'} value={timestamp / duration * 100} />
                <Text minW={'55px'} fontSize={'xs'} color={'secondaryGray.500'} ml={2} mt={'15px'}>{helpers.getTimeStamp(duration * 1000)}</Text>
            </Flex>
            <ReactAudioPlayer
                src={src}
                ref={audioRef}
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                onLoadedMetadata={(e) => setMetdata(e)}
                listenInterval={listenInterval}
                onListen={handleOnListen}
            />
        </>
    )
}
