import { Box, SimpleGrid, Flex, Text, Select, Input, FormControl, FormLabel, InputGroup, InputRightElement, Button, Icon, Checkbox, useColorModeValue, Link } from "@chakra-ui/react"
import React, { useEffect } from "react"
import Card from "components/card/Card.js"
import { HSeparator } from "components/separator/Separator"
import { useStore } from "store"

export default function Settings() {
  const textColor = useColorModeValue("navy.700", "white")
  const textColorSecondary = "gray.400"
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600")
  const textColorBrand = useColorModeValue("brand.500", "white")
  const brandStars = useColorModeValue("brand.500", "brand.400")
  
  return (
    <Box>
      <SimpleGrid
        mb='20px'
        columns={{ base: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <Card align='left' direction='column' w='100%'>
          <Flex align='left' w='100%' px='15px' py='10px'>
            <Text
              me='auto'
              color={'white'}
              fontSize='xl'
              fontWeight='700'
              lineHeight='100%'>
              Bleeping Settings
            </Text>
          </Flex>
          <Flex align='left' w='100%' px='15px' py='10px'>
            <Text
              me='auto'
              color={'white'}
              fontSize='sm'
              fontWeight='200'
              lineHeight='100%'>
              Configure how files are bleeped.
            </Text>
          </Flex>
          <HSeparator mt={1} mb={5}></HSeparator>
          <Flex align='center' w={{ base: "100%", "2xl": "50%" }} px='15px' py='10px'>
            <FormControl>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Setting 1 <Text color={brandStars}> *</Text>
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='Gary Cowgill'
                mb='24px'
                fontWeight='500'
                size='lg'
              />
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                mb='8px'>
                Setting 2
              </FormLabel>
              <Input
                isRequired={true}
                variant='auth'
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                type='email'
                placeholder='Another value'
                mb='24px'
                fontWeight='500'
                size='lg'
              />

              <Button
                fontSize='sm'
                variant='brand'
                fontWeight='500'
                w='25%'
                h='50'
                mb='24px'>
                Save
              </Button>
            </FormControl>
          </Flex>
        </Card>
      </SimpleGrid>
    </Box >
  )
}
