import { Box, Badge, Text } from "@chakra-ui/react"
import { NavLink } from "react-router-dom"
import { useStore } from 'store'

function PlanBadge(props) {
  const { variant, children, ...rest } = props
  const { subscription } = useStore()

  return (
    <Box display={{ base: 'none', md: 'block' }}>
      <NavLink to={'/manage-subscription'} >
        <Badge variant="solid" colorScheme="green" textTransform={'none'} fontSize={'sm'} marginY={4} fontWeight={'bold'}>{subscription?.product_name || 'Free Plan'}</Badge>
      </NavLink>
    </Box>
  )
}

export default PlanBadge
