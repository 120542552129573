import React, { useState } from "react"
import { NavLink } from "react-router-dom"
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner
} from "@chakra-ui/react"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { RiEyeCloseLine } from "react-icons/ri"
import logo from "assets/img/logo.png"
import { supabase } from "supabase"
import { useHistory } from "react-router-dom"
import { HSeparator } from "components/separator/Separator"
import { isValidEmail } from 'is-valid-email'
import { useStore } from 'store'

function SignIn() {
  const { addAlert } = useStore()
  const textColor = useColorModeValue("navy.700", "white")
  const textColorSecondary = "gray.400"
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600")
  const textColorBrand = useColorModeValue("brand.500", "white")
  const authBg = useColorModeValue("secondaryGray.300", "navy.800")

  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const handlePasswordToggle = () => setShow(!show)
  const history = useHistory()

  const handleLogin = async () => {
    if (email && password) {
      setLoading(true)
      const { data, error } = await supabase.auth.signInWithPassword({ email, password })
      setLoading(false)
      if (error) {
        if (!error.message || error.message === 'Failed to fetch') {
          error.message = 'Something went wrong. Please try again.'
        }

        addAlert({ status: 'error', message: error.message })
      }
      else {
        history.push('/dashboard')
      }
    }
    else {
      addAlert({ status: 'error', message: 'Please enter email and password.' })
    }
  }

  const handleKeyDown = async (event) => {
    if (event.key === 'Enter') {
      await handleLogin()
    }
  }

  const textBoxProps = {
    variant: 'auth',
    onKeyDown: handleKeyDown,
    fontSize: 'sm',
    ms: { base: "0px", md: "0px" },
    mb: '3',
    fontWeight: '500',
    size: 'lg'
  }

  return (
    <Flex
      bg={authBg}
      borderRadius='15px'
      p={{ base: 5, md: 10, lg: 25 }}
      minHeight={'500px'}
      borderWidth={'1px'}
      borderColor={useColorModeValue('secondaryGray.200', '#212d37')}>
      {loading && <Spinner margin={'auto'} />}
      {!loading && (
        <Box width={'100%'} marginX={'auto'}>
          <Flex >
            <Image mx={'auto'} src={logo} w='100px' h='100px' />
          </Flex>
          <Flex >
            <Heading color={textColor} mx="auto" mt={5} mb={5} fontSize='4xl'>
              bleep-it
            </Heading>
          </Flex>
          <FormControl>
            <Input
              value={email}
              onChange={e => setEmail(e.target.value.trim())}
              placeholder='Email Address'
              {...textBoxProps} />
            <InputGroup size='md'>
              <Input
                onChange={e => setPassword(e.target.value)}
                value={password}
                placeholder='Password'
                type={show ? "text" : "password"}
                {...textBoxProps}
                marginBottom={1} />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handlePasswordToggle}
                />
              </InputRightElement>
            </InputGroup>
            <Box width={'100%'} textAlign={'right'} mb='5'>
              <NavLink to='/forgot-password'>
                <Text
                  color={textColorDetails}
                  fontSize='sm'
                  w='124px'
                  marginLeft={'auto'}
                  fontWeight='500'>
                  Forgot password?
                </Text>
              </NavLink>
            </Box>
            <Button
              onClick={handleLogin}
              fontSize='lg'
              variant='brand'
              fontWeight='bold'
              w='100%'
              h='50'
              mb='10px'>
              Log in
            </Button>
          </FormControl>
          <HSeparator mt={1} mb={3} />
          <Flex
            flexDirection='column'
            justifyContent='center'
            maxW='100%'
            mt='0px'>
            <Text color={textColorBrand} textAlign={'right'} fontWeight='400' fontSize='14px'>
              Need an account?
              <NavLink to='/sign-up'>
                <Text
                  color={textColorDetails}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  Create an account
                </Text>
              </NavLink>
            </Text>
          </Flex>
        </Box>
      )}
    </Flex>
  )
}

export default SignIn
