import {
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue
} from "@chakra-ui/react"
import { Notification } from 'components/menu/Notification'
import { useStore } from 'store'
import { MdNotificationsNone, MdNotificationsActive } from "react-icons/md"
import notificationService from "services/notificationService"

function NotificationBell(props) {
    const { variant, children, ...rest } = props
    let menuBg = useColorModeValue("white", "navy.800")
    const textColorBrand = useColorModeValue("brand.700", "brand.400")
    const { notifications, fetchNotifications } = useStore()
    const markAllRead = () => {
        notificationService.markAllAsViewed().then(() => {
            fetchNotifications()
        })
    }

    const iconColor = notifications.length > 0 ? 'brand.500' : 'white'

    return (
        <Menu>
            <MenuButton p='0px'>
                <Icon
                    mt='6px'
                    color={iconColor}
                    as={notifications.length > 0 ? MdNotificationsActive : MdNotificationsNone}
                    w='30px'
                    h='30px'
                />
            </MenuButton>
            <MenuList
                borderRadius='20px'
                bg={menuBg}
                border='none'
                maxW={'100vw'}>
                {notifications.length > 0 ? (<Flex jusitfy='space-between' w='100%' mb='0px'>
                    <Text
                        fontSize='sm'
                        fontWeight='500'
                        color={textColorBrand}
                        ms='auto'
                        pt={2}
                        pb={2}
                        marginRight={3}
                        onClick={markAllRead}
                        cursor='pointer'>
                        Mark all read
                    </Text>
                </Flex>) : (<MenuItem
                    marginX={'auto'}
                    _hover={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    paddingTop={4}
                    paddingBottom={2}
                    width={'fit-content'}>
                    No Notifications
                </MenuItem>)}
                <Flex flexDirection='column' maxWidth={'450px'}>
                    {notifications?.map((notification) => {
                        return (
                            <MenuItem
                                paddingTop={0}
                                _hover={{ bg: "none" }}
                                _focus={{ bg: "none" }}
                                width={'100%'}
                                px='0'
                                pb={0}
                                key={notification.id}
                                borderRadius='8px'>
                                <Notification key={notification.id} notification={notification}></Notification>
                            </MenuItem>)
                    })}
                    <MenuItem
                        marginX={'auto'}
                        _hover={{ bg: "none" }}
                        _focus={{ bg: "none" }}
                        paddingY={2}
                        width={'fit-content'}>
                    </MenuItem>
                </Flex>
            </MenuList>
        </Menu>
    )
}

export default NotificationBell
