import React, { useState } from "react"
import { NavLink } from "react-router-dom"
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  Text,
  useColorModeValue,
  Spinner
} from "@chakra-ui/react"
import logo from "assets/img/logo.png"
import { supabase } from "supabase"
import { useHistory } from "react-router-dom"
import { HSeparator } from "components/separator/Separator"
import { isValidEmail } from 'is-valid-email'
import { useStore } from 'store'

function ForgotPassword() {
  const { addAlert } = useStore()
  const textColor = useColorModeValue("navy.700", "white")
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600")
  const brandStars = useColorModeValue("brand.500", "brand.400")
  const authBg = useColorModeValue("secondaryGray.300", "navy.800")

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState("")
  const history = useHistory()

  const requestResetLink = async () => {
    if (email && isValidEmail(email)) {
      setLoading(true)

      const { data, error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          emailRedirectTo: window.location.origin
        }
      })
      setLoading(false)
      if (error) {
        addAlert({ status: 'error', message: error.message || 'Something went wrong.' })
      }
      else {
        history.push('/sign-in')
      }
    }
    else {
      addAlert({ status: 'error', message: 'Please enter a valid email.' })
    }
  }
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      requestResetLink()
    }
  }

  return (
    <Flex
      bg={authBg}
      borderRadius='15px'
      p={25}
      minHeight={'500px'}
      borderWidth={'1px'}
      borderColor={useColorModeValue('secondaryGray.200', '#212d37')}>
      {loading && <Spinner margin={'auto'} />}
      {!loading && (
        <Box width={'100%'} marginX={'auto'}>
          <Flex >
            <Image mx={'auto'} src={logo} w='100px' h='100px' />
          </Flex>
          <Flex >
            <Heading color={textColor} mx="auto" mt={5} fontSize='36px' mb='10px'>
              bleep-it
            </Heading>
          </Flex>
          <Flex width={'100%'} mt={2} mb={5}>
            <Text textAlign={'center'} marginX={'auto'}>Request a magic link to login and reset your password</Text>
          </Flex>
          <FormControl>
            <Input
              isRequired={true}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={handleKeyDown}
              value={email}
              fontSize='sm'
              placeholder='Email Address'
              size='lg'
              mb='24px'
              type={"text"}
              variant='auth'
            />
            <Button
              onClick={requestResetLink}
              fontSize='lg'
              variant='brand'
              fontWeight='bold'
              w='100%'
              h='50'
              mb='12px'>
              Send Magic Link
            </Button>

            <HSeparator mt={4} mb={2}></HSeparator>
            <Text textAlign={'right'} fontWeight='400' fontSize='14px'>
              remember your password?
              <NavLink to='/sign-in'>
                <Text
                  color={textColorDetails}
                  as='span'
                  ms='5px'
                  fontWeight='500'>
                  log in
                </Text>
              </NavLink>
            </Text>
          </FormControl>
        </Box>
      )}
    </Flex>
  )
}

export default ForgotPassword
