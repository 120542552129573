import React, { useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import routes from "routes.js"
import { Alert, Box } from "@chakra-ui/react"
import { useStore } from 'store'

export default function Auth() {
  const { alerts } = useStore()

  const getRoutes = (routes) => {
    routes = routes.filter(r => !r.protected)
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.items)
      }
      if (prop.category) {
        return getRoutes(prop.items)
      } else {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        )
      }
    })
  }

  return (
    <Box
      height='100%'
      position='relative'
      w='100%'
      transition='all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)'
      transitionDuration='.2s, .2s, .35s'
      transitionProperty='top, bottom, width'
      transitionTimingFunction='linear, linear, ease'>
      <Box mx='auto' minH='80vh'>
        <Box width={'100%'} position={'fixed'} top={0} >
          {alerts.map((alert, index) => (
            <Alert key={`alert-${index}`} status={alert.status} variant={'left-accent'}>{alert.message}</Alert>
          ))}
        </Box>
        <Box
          maxW={{ sm: '450px', md: "450px" }}
          marginX={'auto'}
          mt={{ base: "40px", md: "6vh" }}
          mb={'25px'}>
          <Switch>
            {getRoutes(routes)}
            <Redirect
              from='/'
              to='/sign-in/'
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  )
}
