
import { supabase } from 'supabase'

async function getUsage(organizationId, timeframe) {
    var query = {
        'organization_id': organizationId
    }
    var today = new Date()

    switch (timeframe) {
        case 'This Month':
            query.month = today.getMonth() + 1
            query.year = today.getFullYear()
            break
        case 'Last Month':
            query.month = today.getMonth()
            query.year = today.getFullYear()
            break
        case 'This Year':
            query.year = today.getFullYear()
            break
        case 'Today':
            query.day = today.getDate()
            query.month = today.getMonth() + 1
            query.year = today.getFullYear()
            break
        default:
            return
    }

    const { data, error } = await supabase
        .from('daily_usage')
        .select('*')
        .match(query)

    if (error) {
        console.log('error', error)
        return
    }

    var seconds_transcribed = data.reduce((sum, item) => sum + item.seconds_transcribed, 0)

    var words_bleeped = data.reduce((sum, item) => sum + item.words_bleeped, 0)
    var files_uploaded = data.reduce((sum, item) => sum + item.files_uploaded, 0)

    return { seconds_transcribed, words_bleeped, files_uploaded }
}
export default { getUsage }
