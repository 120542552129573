import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react"
import React from "react"
import helpers from 'utils/helpers'
import { useHistory } from "react-router-dom"
import notificationService from "services/notificationService"
import { useStore } from 'store'

export function Notification(props) {
  const textColor = useColorModeValue("navy.700", "white")
  const { notification } = props
  const history = useHistory()
  const { fetchNotifications } = useStore()

  //handle click event
  const handleClick = () => {
    if (notification) {
      //mark notification as read
      notificationService.markAsViewed(notification.id).then(() => {
        //call fetch notifications from store
        fetchNotifications()
      })

      if (notification?.link) {
        //navigate to link
        history.push(notification.link)
      }
    }
  }

  return (
    <Box onClick={handleClick} width={'100%'} paddingX={4} paddingY={2} borderBottomWidth={1} borderTopWidth={1} borderColor={"#212d37"} _hover={{ bg: 'navy.700' }}>
      <Flex position={'relative'} justifyItems={'start'}>
        <Box>
          <Text
            fontWeight='bold'
            color={textColor}
            fontSize={{ base: "sm", md: "sm" }}>
            {notification.title}
          </Text>
          <Flex alignItems='center' flexWrap={'wrap'}>
            <Text
              color={'secondaryGray.500'}
              fontSize={{ base: "sm", md: "sm" }}>
              {notification.message}
            </Text>
          </Flex>
          <Flex>
            <Text
              ml={'auto'}
              mt={1}
              color={'secondaryGray.500'}
              fontSize={{ base: "xs", md: "xs" }}>
              {helpers.timeAgo(notification.created_at.toLocaleString())}
            </Text>
          </Flex>
        </Box>
      </Flex >
    </Box >
  )
}
