import React from "react"

import { Icon } from "@chakra-ui/react"
import {
  MdHome,
  MdAudiotrack,
  MdSettings,
} from "react-icons/md"

// Admin Imports
import Dashboard from "views/admin/dashboard"
import RecordingsList from "views/admin/recordingsList"
import Settings from "views/admin/settings"
import AccountSettings from "views/admin/accountSettings"
import RecordingDetails from "views/admin/recordingDetails"
import SignIn from "views/auth/signIn"
import SignUp from "views/auth/signUp"
import ForgotPassword from "views/auth/forgotPassword"
import ManageSubscription from "views/admin/manageSubscription"
import Search from "views/admin/search"

const routes = [
  {
    name: "dashboard",
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: Dashboard,
    showInNav: true,
    protected: true
  },
  {
    name: "recordings",
    path: "/recordings",
    icon: (
      <Icon
        as={MdAudiotrack}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: RecordingsList,
    secondary: true,
    showInNav: true,
    protected: true
  },
  {
    name: "settings",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    path: "/settings",
    component: Settings,
    showInNav: true,
    protected: true
  },
  {
    name: "settings",
    path: "/account-settings",
    component: AccountSettings,
    showInNav: false,
    protected: true
  },
  {
    name: "ManageSubscription",
    path: "/manage-subscription",
    component: ManageSubscription,
    showInNav: false,
    protected: true
  },
  {
    name: "RecordingDetails",
    path: "/recording/:id",
    component: RecordingDetails,
    showInNav: false,
    protected: true
  },
  {
    name: "Search",
    path: "/search/:query",
    component: Search,
    showInNav: false,
    protected: true
  },
  {
    name: "SignIn",
    path: "/sign-in",
    component: SignIn,
    showInNav: false
  },
  {
    name: "SignUp",
    path: "/sign-up",
    component: SignUp,
    showInNav: false
  },
  {
    name: "ForgotPassword",
    path: "/forgot-password",
    component: ForgotPassword,
    showInNav: false
  },
]

export default routes
