import { Flex, useColorModeValue } from "@chakra-ui/react"
import React from "react"



const HSeparator = (props) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100")
  const { variant, children, ...rest } = props
  return <Flex h='1px' w='100%' bg={borderColor} {...rest}></Flex>
}

const VSeparator = (props) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100")
  const { variant, children, ...rest } = props
  return <Flex w='1px' bg={borderColor} {...rest}></Flex>
}

export { HSeparator, VSeparator }
