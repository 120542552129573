import create from 'zustand'
import { supabase } from 'supabase'
import helpers from 'utils/helpers'
import notificationService from 'services/notificationService'

export const useStore = create((set, get) => ({
    session: null,
    subscription: null,
    userProfile: null,
    organizationId: null,
    alerts: [],
    notifications: [],
    addAlert: (alert, ttl) => {
        //make sure alert is not a duplicate
        if (get().alerts.find(a => a.message == alert.message)) {
            return
        }

        //tag alert with unique id
        const id = helpers.uid()
        alert.id = id

        //add alert to state
        set({ alerts: [...get().alerts, alert] })

        //remove alert after 5 seconds
        setTimeout(() => {
            set({ alerts: get().alerts.filter(a => a.id != id) })
        }, ttl || 5000)
    },
    setSession: async (session) => {
        set({ session })
        await get().fetchUserProfile()
        await get().fetchSubscription()
        await get().fetchNotifications()
    },
    fetchUserProfile: async () => {
        let session = get().session

        if (session) {
            const { data, error } = await supabase
                .from('user_profiles')
                .select('*')
                .eq('user_id', session.user.id).single()

            if (error) {
                console.log('error', error)
            } else {
                set({ userProfile: data })
                set({ organizationId: data.organization_id })
            }
        }
    },
    fetchNotifications: async () => {
        let session = get().session

        if (session) {
            var data = await notificationService.getNotifications()
            if (data) {
                set({ notifications: data })
            }
        }
    },
    fetchSubscription: async () => {
        let session = get().session

        if (session) {
            const { data, error } = await supabase
                .from('subscriptions')
                .select('*')
                .match({ organization_id: get().organizationId, status: 'active' })

            if (error) {
                console.log('error', error)
                return
            }
            else if (data.length > 0) {
                set({ subscription: data[0] })
            }
            else {
                set({ subscription: null })
            }
        }
    },
    saveUserProfile: async (updatedProfile) => {
        let session = get().session

        if (session) {
            const { data, error } = await supabase
                .from('user_profiles')
                .update({ ...updatedProfile })
                .eq('user_id', session.user.id)
                .select().single()

            if (error) {
                console.log('error', error)
            } else {
                set({ userProfile: data })
            }
        }
    },
    updatePassword: async (password) => {
        const { error } = await supabase.auth.updateUser({ password })
        if (error) {
            console.log('error', error)
        }
    }
}))