// Chakra imports
import { Alert, AlertIcon, Box } from "@chakra-ui/react"
import Navbar from "components/navbar"
import React, { useState } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import routes from "routes.js"
import { useStore } from 'store'
import FooterAdmin from "components/footer/FooterAdmin.js"

export default function Dashboard(props) {
  const { ...rest } = props
  const { alerts } = useStore()

  const getRoutes = (routes) => {
    routes = routes.filter(r => r.protected)
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.items)
      }
      if (prop.category) {
        return getRoutes(prop.items)
      } else {
        return (
          <Route
            path={prop.path}
            component={prop.component}
            key={key}
          />
        )
      }
    })
  }

  return (
    <Box w={'100%'} overflowX={'hidden'}>
      <Navbar />
      <Box>
        {alerts.map((alert, index) => (
          <Alert key={`alert-${index}`} status={alert.status} variant={'subtle'}><AlertIcon />{alert.message}</Alert>
        ))}

      </Box>
      <Box
        mx='auto'
        p={{ base: "20px", md: "30px" }}
        minH='100vh'>
        <Switch>
          {getRoutes(routes)}
          <Redirect from='/' to='/dashboard' />
        </Switch>
      </Box>
      <FooterAdmin />
    </Box>
  )
}
