const helpers = {
    formatPhone(input, previousInput) {
        if (!input) return input
        const currentInput = input.replace(/[^\d]/g, '')
        const ciLength = currentInput.length

        if (!previousInput || input.length > previousInput.length) {
            if (ciLength < 4) return currentInput
            if (ciLength < 7)
                return `(${currentInput.slice(0, 3)}) ${currentInput.slice(3)}`
            return `(${currentInput.slice(0, 3)}) ${currentInput.slice(
                3,
                6,
            )}-${currentInput.slice(6, 10)}`
        }
    },
    formatName(input) {
        if (input) {
            var fullName = input.split(' ')
            if (fullName[2] && fullName[2].length) {
                input = fullName[1] + ' ' + fullName[2] + ' ' + fullName[0]
            } else {
                input = input.split(' ')[1] + ' ' + input.split(' ')[0]
            }
            return input.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }

        return ''
    },
    uid() {
        return "id" + Math.random().toString(16).slice(2)
    },
    getTimeStamp(duration, isSeconds = false) {
        if (isSeconds) {
            duration = duration * 1000
        }

        var seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

        hours = (hours < 10) ? "0" + hours : hours
        minutes = (minutes < 10) ? "0" + minutes : minutes
        seconds = (seconds < 10) ? "0" + seconds : seconds

        return hours + ":" + minutes + ":" + seconds
    },
    toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            }
        )
    },
    formatCurrency(amount) {
        // Create our number formatter.
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        })

        return formatter.format(amount)
    },
    timeAgo(input) {
        const date = (input instanceof Date) ? input : new Date(input)
        const formatter = new Intl.RelativeTimeFormat('en')
        const ranges = [
            ['years', 3600 * 24 * 365],
            ['months', 3600 * 24 * 30],
            ['weeks', 3600 * 24 * 7],
            ['days', 3600 * 24],
            ['hours', 3600],
            ['minutes', 60],
            ['seconds', 1],
        ]
        const secondsElapsed = (date.getTime() - Date.now()) / 1000

        for (const [rangeType, rangeVal] of ranges) {
            if (rangeVal < Math.abs(secondsElapsed)) {
                const delta = secondsElapsed / rangeVal
                return formatter.format(Math.round(delta), rangeType)
            }
        }
    }
}

export default helpers