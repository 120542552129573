import React, { useState } from "react"
import { NavLink } from "react-router-dom"
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Spinner
} from "@chakra-ui/react"
import { MdOutlineRemoveRedEye } from "react-icons/md"
import { RiEyeCloseLine } from "react-icons/ri"
import logo from "assets/img/logo.png"
import { supabase } from "supabase"
import { useHistory } from "react-router-dom"
import { HSeparator } from "components/separator/Separator"
import { isValidEmail } from 'is-valid-email'
import { useStore } from 'store'

function SignIn() {
  const { addAlert } = useStore()
  const textColor = useColorModeValue("navy.700", "white")
  const textColorSecondary = "gray.400"
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600")
  const brandStars = useColorModeValue("brand.500", "brand.400")
  const authBg = useColorModeValue("secondaryGray.300", "navy.800")

  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")

  const togglePasswordVisible = () => setShowPassword(!showPassword)
  const history = useHistory()

  const signUp = async () => {
    if (firstName && lastName && email && password && confirmPassword) {
      if (!isValidEmail(email)) {
        addAlert({ status: 'error', message: 'Please enter a valid email.' })
        return
      }
      if (password !== confirmPassword) {
        addAlert({ status: 'error', message: 'Passwords do not match.' })
        return
      }

      setLoading(true)

      const { error } = await supabase.auth.signUp({
        email, password,
        options: {
          data: {
            first_name: firstName,
            last_name: lastName
          },
          emailRedirectTo: window.location.origin
        }
      })

      setLoading(false)

      if (error) {
        addAlert({ status: 'error', message: error.message || 'Something went wrong.' })
      }
      else {
        addAlert({ status: 'success', message: 'Success! Confirm email address to access your account.' }, 60000)
        history.push('/sign-in')
      }
    }
    else {
      addAlert({ status: 'error', message: 'Please enter all required fields.' })
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      signUp()
    }
  }

  const formLabelProps = {
    display: 'flex',
    ms: '4px',
    fontSize: 'md',
    fontWeight: 'bold',
    color: textColor,
    mb: '8px'
  }

  const textBoxProps = {
    variant: 'auth',
    onKeyDown: handleKeyDown,
    fontSize: 'sm',
    ms: { base: "0px", md: "0px" },
    mb: '24px',
    fontWeight: '500',
    size: 'lg'
  }

  const renderPWToggle = () => {
    return (<InputRightElement display='flex' alignItems='center' mt='4px'>
      <Icon
        color={textColorSecondary}
        _hover={{ cursor: "pointer" }}
        as={showPassword ? RiEyeCloseLine : MdOutlineRemoveRedEye}
        onClick={togglePasswordVisible}
      />
    </InputRightElement>)
  }

  const renderSignupForm = () => {
    return (
      <FormControl>
        <Input
          value={firstName}
          onChange={e => setFirstName(e.target.value.trim())}
          placeholder='First Name'
          {...textBoxProps}
        />
        <Input
          value={lastName}
          onChange={e => setLastName(e.target.value.trim())}
          placeholder='Last Name'
          {...textBoxProps}
        />
        <Input
          value={email}
          onChange={e => setEmail(e.target.value.trim())}
          placeholder='Email Address'
          {...textBoxProps}
        />
        <InputGroup size='md'>
          <Input
            onChange={e => setPassword(e.target.value)}
            value={password}
            placeholder='Password'
            type={showPassword ? "text" : "password"}
            {...textBoxProps}
          />
          {renderPWToggle()}
        </InputGroup>
        <InputGroup size='md'>
          <Input
            onChange={e => setConfirmPassword(e.target.value)}
            value={confirmPassword}
            placeholder='Confirm Password'
            type={showPassword ? "text" : "password"}
            {...textBoxProps}
          />
          {renderPWToggle()}
        </InputGroup>
        <Button
          onClick={signUp}
          fontSize='lg'
          fontWeight={'bold'}
          variant='brand'
          w='100%'
          h='50'
          mb='12px'>
          Create Account
        </Button>
      </FormControl>
    )
  }

  return (
    <Flex
      bg={authBg}
      minHeight={'500px'}
      borderRadius='15px'
      p={25}
      borderWidth={'1px'}
      borderColor={useColorModeValue('secondaryGray.200', '#212d37')}>
      {loading && <Spinner margin={'auto'} />}
      {!loading && (
        <Box width={'100%'} marginX={'auto'}>
          <Flex >
            <Image mx={'auto'} src={logo} w='100px' h='100px' />
          </Flex>

          <Flex >
            <Heading color={textColor} mx="auto" mt={5} mb={5} fontSize='4xl'>
              bleep-it
            </Heading>
          </Flex>

          {renderSignupForm()}

          <Text fontSize="xs" color={textColorSecondary} textAlign={'center'} mb={'0'}>By clicking this button you agree to the terms and conditions outlined within the End User License Agreement (EULA).</Text>

          <HSeparator mt={4} mb={2}></HSeparator>

          <Text textAlign={'right'} fontWeight='400' fontSize='14px'>
            Already have an account?
            <NavLink to='/sign-in'>
              <Text
                color={textColorDetails}
                as='span'
                ms='5px'
                fontWeight='500'>
                Log In
              </Text>
            </NavLink>
          </Text>
        </Box>
      )}
    </Flex>
  )
}

export default SignIn
