
import { supabase } from 'supabase'

async function markAsViewed(notificationId) {
    const { data, error } = await supabase
        .from('notifications')
        .update({ viewed: true })
        .eq('id', notificationId)

    if (error) {
        console.log('error', error)
        return
    }

    return data
}

async function markAllAsViewed() {
    const { data, error } = await supabase
        .from('notifications')
        .update({ viewed: true })
        .eq('viewed', false)

    if (error) {
        console.log('error', error)
        return
    }

    return data
}

async function getNotifications() {
    const { data, error } = await supabase
        .from('notifications')
        .select('*')
        .eq('viewed', false)
        .limit(5)
        .order('created_at', { ascending: false })

    if (error) {
        console.log('error', error)
        return
    }

    return data
}

export default { markAsViewed, markAllAsViewed, getNotifications }
