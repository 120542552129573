import {
    Flex,
    Text,
    IconButton,
    ButtonGroup
} from "@chakra-ui/react"
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons"

function Pagination(props) {
    const { pageOptions, nextPage, previousPage, pageIndex } = props

    return (
        <Flex ml={'auto'} display={pageOptions.length == 0 ? 'none' : 'block'}>
            <ButtonGroup size="sm" mr={5} isAttached variant="outline">
                <IconButton
                    onClick={previousPage}
                    color={'secondaryGray.600'}
                    icon={<ChevronLeftIcon h={6} w={6} />}
                />
                <IconButton borderX={0} fontWeight={'normal'} >
                    <Text fontSize={'sm'} color={'secondaryGray.600'} marginX={3}>
                        Page{" "}
                        <Text as="span">
                            {pageIndex + 1}
                        </Text>{" "}
                        of{" "}
                        <Text as="span">
                            {pageOptions.length}
                        </Text>
                    </Text>
                </IconButton>
                <IconButton
                    onClick={nextPage}
                    color={'secondaryGray.600'}
                    icon={<ChevronRightIcon h={6} w={6} />}
                />
            </ButtonGroup>
        </Flex>
    )
}

export default Pagination
