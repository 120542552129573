// Chakra imports
import {
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react"
// Custom components
import React from "react"

export default function Default(props) {
  const { id, label, extra, placeholder, required, type, mb, ...rest } = props
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white")
  const brandStars = useColorModeValue("brand.500", "brand.400")

  return (
    <Flex direction='column' mb={mb ? mb : "20px"}>
      <FormLabel
        display='flex'
        ms='10px'
        htmlFor={id}
        fontSize='sm'
        color={textColorPrimary}
        fontWeight='bold'
        _hover={{ cursor: "pointer" }}>
        {label}
        <Text fontSize='sm' fontWeight='400' ms='2px'>
          {extra}
        </Text>
        {required && (
          <Text color={brandStars}>*</Text>
        )}
      </FormLabel>
      <Input
        {...rest}
        type={type}
        id={id}
        fontWeight='500'
        variant='main'
        placeholder={placeholder}
        _placeholder={{ fontWeight: "400", color: "secondaryGray.600" }}
        h='44px'
        maxh='44px'
      />
    </Flex>
  )
}
